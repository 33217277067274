<template>
  <div class="register-form-wrap">
    <div class="register-form-content">
      <el-result icon="success" title="恭喜您注册成功">
        <template slot="extra">
          <div class="register__result-info">
            <div>入驻申请成功可进行以下操作，建议您立即入驻</div>
            <div class="register__result-ul">
              <ul style="list-style-type: disc; text-align: left; margin-top: 20px">
                <li>获取对接信息</li>
                <li>自动化接口测试</li>
                <li>全场景覆盖业务验证</li>
                <li>快速上线进行业务开展</li>
              </ul>
            </div>
          </div>
          <el-button style="width: 312px; height: 42px" type="primary" size="medium" @click="onSettledClick"
            >登录并入驻
          </el-button>
        </template>
      </el-result>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RegisterSuccessPage',
  components: {},
  data () {
    return {}
  },
  mounted () {},
  methods: {
    onSettledClick () {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss">
.register-form-wrap {
  background: url(../../../assets/images/register/gtm-register-bg@2x.png) center center no-repeat;
  background-size: 100% 100%;
  padding: 4.52rem 0 20px;
  height: 100%;
  box-sizing: border-box;

  .register-form-content {
    width: 1190px;
    margin: auto;
    margin-top: 50px;
    background: #fff;
    padding: 10px 60px 60px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    // text-align: center;
    h2 {
      font-size: 30px;
      font-weight: 600;
      color: #444444;
      margin: 30px 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    .register-form {
      padding: 40px 310px 40px 230px;
      border: 1px solid #d2d2d2;
    }

    .register__result-info {
      font-size: 14px;
      background-color: rgba(242, 242, 242, 1);
      padding: 16px;
      margin-bottom: 16px;

      .register__result-ul {
        margin-left: 17px;
      }
    }
  }
}
</style>
